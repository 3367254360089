import React, { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { insertOpenIcon } from "../../functions/addOpenInNewTab";
import accessibility from "../Navbar/accessibility.module";
import ReactMarkDown from "react-markdown";
import useFetchWithMsal from "../../hooks/useFetchWithMsal";
import { protectedResources } from "../../authConfig";
import ScoringModal from "./ScoringModal";
import { TestMap } from "../../functions/aidTestMap";
import { getIssues } from "../../functions/calculateResults";
import { getItem, setItem, updateItem } from "../../utilities/storage";
import { ResultsTable } from "../../utilities/tables";
import { deleteWebsite } from "../../utilities/api";
import DOMPurify from 'dompurify';

const capabilityValuesToId = {
  UX: 1,
  VisualDesign: 2,
  XT: 3,
  Triage: 4,
  Copy: 5,
  NA: 6,
};

const idToCapability = {
  1: "UX",
  2: "VisualDesign",
  3: "XT",
  4: "Triage",
  5: "Copy",
  6: "NA",
};

const ScoringForm = (props) => {
  const {
    currentPage,
    setCurrentPage,
    incompleteQuestions,
    currentCriteria,
    testId,
    numQuestions,
    testIncompleteError,
    setTestIncompleteError,
    setApplyIncompleteFocus,
    setIncompleteQuestions,
    setCurrentCriteria,
    headingRef,
    submitQuestion,
    setSubmitQuestion,
    isEditCompleted
  } = props;

  let [urlText, setUrlText] = useState("");
  let [noteText, setNoteText] = useState("");
  let [answerScore, setAnswerScore] = useState(null);
  let [selectedCapability, setSelectedCapability] = useState("");
  let [showCapability, setShowCapability] = useState(true);
  let [urlError, setUrlError] = useState(false);
  let [notesError, setNotesError] = useState(false);
  let [scoreButtonError, setScoreButtonError] = useState(false);
  let [capabilityError, setCapabilityError] = useState(false);
  let [visualExImage, setVisualExImage] = useState(null);
  let [notesRequired, setNotesRequired] = useState(true);
  let [accountName, setAccountName] = useState("");
  let [projectName, setProjectName] = useState("");
  let [currentReasonings, setCurrentReasonings] = useState(null);
  let [bttnValue, setBttnValue] = useState("Next");
  let [formSubmitted, setFormSubmitted] = useState(false);
  let [prefillNeeded, setPrefillNeeded] = useState(false);
  let [applyFormFocus, setApplyFormFocus] = useState(false);
  let [showAbandonModal, setShowAbandonModal] = useState(false);
  let [onAbandonCallback, setOnAbandonCallback] = useState(null);
  let [resultsMessages, setResultsMessages] = useState(['No Results']);
  let [isCompleted, setCompleted] = useState(false);

  const formRef = useRef(null);
  const location = useLocation();
  const navigate = useNavigate();
  const { state } = location;
  const { execute } = useFetchWithMsal({
    scopes: [
      ...protectedResources.testEndpoint.scopes.read,
      ...protectedResources.getPopeTech.scopes.read,
      ...protectedResources.updatePopeTechInfo.scopes.write
    ]
  });
  const init = () => {
    /**
     * Get data from DB and insert into Local Storage
     */
    if (state?.question) {
      if (state?.url) {
        setUrlText(state.url);
      }

      if (state?.notes) {
        setNoteText(state.notes);
      }

      if (state?.score) {
        setAnswerScore(Number(state.score));
      }

      if (state?.capability) {
        setSelectedCapability(state.capability);
      }
      setCurrentPage(state.question);
    } else {
      if (incompleteQuestions && incompleteQuestions.length > 0) {
        setCurrentPage(incompleteQuestions[0]);
      } else {
        setCurrentPage(1);
      }
    }
  };

  useEffect(() => {
    if (currentPage === 0) {
      init();
    }
  }, []);

  useEffect(() => {
    let listOfClasses = ["links-markdown", "links-markdown:hover"];
    insertOpenIcon("des", listOfClasses);
    insertOpenIcon("rationale-info", listOfClasses);
    insertOpenIcon("radioButtons", listOfClasses);
  }, [currentCriteria]);

  useEffect(() => {
    if (headingRef && headingRef.current) {
      headingRef.current.focus();
    }

  }, [currentCriteria]);

  useEffect(() => {
    if (prefillNeeded) {
      execute(
        "GET",
        protectedResources.getResponseForCriteria.endpoint +
          testId +
          "/" +
          currentPage,
        protectedResources.getResponseForCriteria.scopes
      ).then((response) => {
        if (response) {
          prefillFields(response);
        }
      });
    }
  }, [prefillNeeded]);

  const printReasonings = () => {
    return currentReasonings.map((item, i) => {
      if (item.reasoning === "This test always applies.") return;
      return (
      <div id={i} key={i}>
        <input
          type="radio"
          id={"opt" + i}
          name="testScore"
          value={item.score ?? -1} // N/A answer has a score of null
          checked={
            answerScore !== null
              ? Number(item.score ?? -1) === Number(answerScore)
              : false
          }
          onChange={() => onAnswerSelection(item.score ?? -1)}
          required
        />
        <label htmlFor={"opt" + i}>
          <ReactMarkDown children={item.reasoning} />
        </label>
      </div>
    )
  });
  };

  const setButtonText = () => {
    if (
      currentCriteria?.criteriaid === numQuestions &&
      bttnValue !== "Submit"
    ) {
      setBttnValue("Submit");
    }
  };

  useEffect(() => {
    if (currentPage > 0) {
      initNextQuestion();
    }
    if(!getItem(testId)) {
      execute("GET", protectedResources.getPopeTech.endpoint + testId, protectedResources.getPopeTech.scopes).then(
        (resp) => {
          if(resp) {
            let results = {
              scan: resp[0].scan,
              wave_results: resp[0].waveresults,
              pages: resp[0].pages,
              scan_id: resp[0].scanid,
              website_id: resp[0].websiteid
            }
            setItem(testId, results);
          }
        }
      ).catch(err => {
        console.error(err);
      })  
    }
  }, [currentPage, execute]);

  useEffect(() => {
    if (testId) {
      execute("GET", protectedResources.getBasicInfo.endpoint + testId, protectedResources.getBasicInfo.scopes).then(
        (response) => {
          if (response && response.length > 0) {
            setAccountName(response[0].companyname);
            setProjectName(response[0].projectname);
            setCompleted(response[0].iscompleted);
          }
        }
      );
    }
  }, [execute, testId]);

  useEffect(() => {
    if (currentPage > 0) {
      // check if we're scanning
      if(getItem(testId)?.scan) {
        let pagesList = getItem(testId).pages.map(page => {
          return page.url;
        }).toString();
        setUrlText(pagesList);
        getIssues(testId, currentPage, execute).then(resp => {
          let output = TestMap.get(currentPage)?.output(resp, testId);
          // clear the current message
          setResultsMessages([]);
          setResultsMessages(resp);
          if(output && !prefillNeeded) setScoreForm(output);
        });
      }
    }
  }, [currentReasonings]);

  const setScoreForm = (results) => {
    // radio button
    document.getElementById(results.answer).click();
    setNoteText(results.notes);
    setSelectedCapability(results.capability);
  }

  const initNextQuestion = () => {
    resetFields();
    execute(
      "GET",
      protectedResources.getCriteriaById.endpoint + currentPage,
      protectedResources.getCriteriaById.scopes
    ).then((response) => {
      if (response) {
        const criteria = response[0];
        execute(
          "GET",
          protectedResources.getReasoning.endpoint + criteria.criteriaid,
          protectedResources.getReasoning.scopes
        ).then((response) => {
          if (response) {
            const reasonings = response.filter(item => item.reasoning !== "OMIT");
            execute(
              "GET",
              protectedResources.getResponseForCriteria.endpoint +
                testId +
                "/" +
                currentPage,
                protectedResources.getResponseForCriteria.scopes
            ).then((response) => {
              if (response) {
                const lastResponse = response;
                execute(
                  "GET",
                  protectedResources.getImage.endpoint + criteria.criteriaid,
                  protectedResources.getImage.scopes
                ).then((response) => {
                  if (response) {
                    let imageid = response[0].imageid;
                    execute(
                      "GET",
                      protectedResources.addImage.endpoint + "/" + imageid,
                      protectedResources.addImage.scopes
                    ).then((response) => {
                      const imageObj = response[0];
                      const image = imageObj.image;
                      const img = {
                        ...imageObj,
                        image: "data:image/png;base64," + image.substr(image.indexOf(",") + 1),
                      };
                      setVisualExImage(img);
                    });
                  }
                });
                setCurrentCriteria(criteria);
                setCurrentReasonings(reasonings);

                if (lastResponse.length > 0) {
                  setPrefillNeeded(true); // will call useEffect() to prefill fields
                }
              }
            });
          }
        });
      }
    });
  };

  const resetFields = () => {
    setUrlText("");
    setNoteText("");
    setAnswerScore(null);
    setSelectedCapability("");
    setScoreButtonError(false);
    setUrlError(false);
    setNotesError(false);
    setCapabilityError(false);
    setShowCapability(true);
    setNotesRequired(true);
    setFormSubmitted(false);
    setApplyIncompleteFocus(false);
    setTestIncompleteError("");
  };

  const prefillFields = (lastResponse) => {
    let scoreButtons = [...document.getElementsByName("testScore")];
    const capabilitySelection = document.getElementsByName("capability");

    setUrlText(lastResponse[0].url);
    setNoteText(lastResponse[0].notes);

    if (scoreButtons) {
      for (let i = 0; i < scoreButtons.length; i++) {
        if (Number(scoreButtons[i].value) === lastResponse[0].responsescore) {
          onAnswerSelection(scoreButtons[i].value);
          break;
        }
      }
    }

    if (capabilitySelection && capabilitySelection.length > 0) {
      setSelectedCapability(idToCapability[lastResponse[0].capabilityid]);
    }

    setPrefillNeeded(false);
  };

  // formats the incomplete question list for the testIncompleteError
  const formatErrorText = (numbers) => {
    if (numbers.length === 0) {
      return "";
    } else if (numbers.length === 1) {
      return numbers[0].toString();
    } else {
      const formattedNumbers = numbers.slice(0, -1).join(", ");
      const lastNumber = numbers[numbers.length - 1];
      return `${formattedNumbers}, and ${lastNumber}`;
    }
  };

  const onSubmit = async (nextPage = null, returntoReport = false) => {
    // when nothing is filled out, allow the user to leave the page without any errors (unless they are trying to submit the last question)
    if (
      answerScore === null &&
      urlText === "" &&
      noteText === "" &&
      selectedCapability === "" &&
      (currentPage !== numQuestions || nextPage)
    ) {
      if (nextPage) {
        setCurrentPage(nextPage);
      } else {
        setCurrentPage(currentPage + 1);
      }
      return;
    }

    setFormSubmitted(true); // even if the form is not submitted successfully, this must be true in order to show dynamic error messages

    const hasScoreButtonError = !validateScoreButtons();
    setScoreButtonError(hasScoreButtonError);
    const hasUrlError = !validateUrl();
    setUrlError(hasUrlError);
    const hasNotesError = !validateNoteText();
    setNotesError(hasNotesError);
    const hasCapabilityError = !validateCapabilitySelection();
    setCapabilityError(hasCapabilityError);

    if (
      hasUrlError ||
      hasNotesError ||
      hasScoreButtonError ||
      hasCapabilityError
    ) {
      // last question should not give the option to leave the page unfinished
      if (currentPage === numQuestions && !nextPage) {
        setApplyFormFocus(true);
        return;
      }

      if (nextPage) {
        setOnAbandonCallback(() => () => {
          setCurrentPage(nextPage);
        })
      } else {
        setOnAbandonCallback(() => () => {
          setCurrentPage(currentPage + 1);
        })
      }

      setShowAbandonModal(true);
      return;
    }

    const capability = selectedCapability
      ? capabilityValuesToId[selectedCapability]
      : capabilityValuesToId["NA"];

    const response = {
      criteriaid: currentCriteria.criteriaid,
      testid: testId,
      url: DOMPurify.sanitize(urlText),
      notes: DOMPurify.sanitize(noteText),
      responsescore: answerScore,
      capabilityid: capability,
    };

    execute("POST", protectedResources.sendOrUpdateResponse.endpoint, protectedResources.sendOrUpdateResponse.scopes, response);

    // update the test incomplete error message because the nav items use this as their aria-describedby value (only after the questionnaire is submitted unsuccessfully)
    const incompleteUpdated = incompleteQuestions.filter(
      (num) => num !== currentPage
    );
    setIncompleteQuestions(incompleteUpdated);
    if (incompleteUpdated.length > 0) {
      setTestIncompleteError(
        `Error: You have not completed question${
          incompleteUpdated.length > 1 ? "s" : ""
        } ${formatErrorText(incompleteUpdated)}. Please fill out the question${
          incompleteUpdated.length > 1 ? "s" : ""
        } and try again.`
      );
    } else {
      setTestIncompleteError("");
    }

    if (nextPage && !returntoReport) {
      setCurrentPage(nextPage);
    } else if (currentPage === numQuestions || returntoReport) {
      if (incompleteUpdated.length === 0) {
        const testFinished = {
          testid: testId,
          testscore: 0,
        };
        execute(
          "PUT",
          protectedResources.updateCompletedTest.endpoint,
          protectedResources.updateCompletedTest.scopes,
          testFinished
        ).then((res) => {
          let isScan = getItem(testId)?.scan;
          // delete the Pope Tech website when complete
          if(isScan) deleteDir();
          navigate("/Results", {
            state: { id: testId, showBackButton: false, score: res?.score },
          });
        })
      } else {
        // applies focus to the first incomplete question on the nav
        setApplyIncompleteFocus(true);
      }
    } else {
      setCurrentPage(currentPage + 1);
    }
  };

  const deleteDir = (evt) => {
    evt?.preventDefault();
    execute("DELETE", protectedResources.deleteLighthouse.endpoint + `/${testId}`, protectedResources.deleteCriteria.scopes).then((resp) => {
      console.log(resp);
    });
  }

  useEffect(() => {
    // trigerred when the nav is clicked
    if (submitQuestion) {
      onSubmit(submitQuestion);
      setSubmitQuestion(null);
    }
  }, [submitQuestion]);

  const onAnswerSelection = (ansValue) => {
    setAnswerScore(Number(ansValue));

    if (Number(ansValue) === -1 || Number(ansValue) === 5) {
      setShowCapability(false);
      setNotesRequired(false);
    } else {
      setShowCapability(true);
      setNotesRequired(true);
    }
  };

  const validateUrl = () => {
    return urlText !== "";
  };

  const validateNoteText = () => {
    // note text is invalid if it is empty AND no score buttons are selected or point values 0-4 are selected
    return !(
      noteText === "" &&
      (answerScore === null || !(answerScore === -1 || answerScore === 5))
    );
  };

  const validateScoreButtons = () => {
    return answerScore !== null;
  };

  const validateCapabilitySelection = () => {
    // capability selection is invalid if nothing is selected AND no score buttons are selected or point values 0-4 are selected
    return !(
      selectedCapability === "" &&
      (answerScore === null || !(answerScore === -1 || answerScore === 5))
    );
  };

  useEffect(() => {
    if (formSubmitted) {
      const hasScoreButtonError = !validateScoreButtons();
      setScoreButtonError(hasScoreButtonError);
    }
  }, [answerScore]);

  useEffect(() => {
    if (formSubmitted) {
      const hasUrlError = !validateUrl();
      setUrlError(hasUrlError);
    }
  }, [urlText]);

  useEffect(() => {
    if (formSubmitted) {
      const hasNotesError = !validateNoteText();
      setNotesError(hasNotesError);
    }
  }, [noteText]);

  useEffect(() => {
    if (formSubmitted) {
      const hasCapabilityError = !validateCapabilitySelection();
      setCapabilityError(hasCapabilityError);
    }
  }, [selectedCapability]);

  useEffect(() => {
    if (!showCapability) {
      setSelectedCapability("");
    }
  }, [showCapability]);

  useEffect(() => {
    if (applyFormFocus) {
      accessibility.applyFormFocus(formRef.current);
      setApplyFormFocus(false);
    }
  }, [applyFormFocus]);

  const getBreadcrumbLink = () => {
    // get page name from path and construct href for breadcrumb
    const page = window.location.pathname.substring(1).split("?")[0];
    return `/company/${accountName}?from=${page}&testId=${testId}`;
  };

  return currentCriteria ? (
    <div className="ssform">
      <nav aria-label="Test metadata breadcrumb" className="breadcrumb">
        <ol>
          <li>
            <a href={getBreadcrumbLink()}>{accountName}</a>
          </li>
          <li>
            <a href={window.location.href} aria-current="page">
              {projectName}
            </a>
          </li>
        </ol>
      </nav>
      <h2 tabIndex="-1" ref={headingRef} id="questions">
        Test {currentCriteria.criteriaid} of {numQuestions}:{" "}
        {currentCriteria.criteria}
      </h2>
      <details>
        <summary className="collapse">Rationale</summary>
        <div className="collapse_info" id="rationale-info">
          <ReactMarkDown children={currentCriteria.rationale} />
        </div>
      </details>

      <details>
        <summary className="collapse">Visual Example</summary>
        <div className="collapse_info">
          {visualExImage?.image && (
            <img
              id="visual-example-image"
              src={visualExImage.image}
              alt={visualExImage.alttext}
            />
          )}
        </div>
      </details>

      <details>
        <summary className="collapse">Test Description</summary>
        <div className="collapse_info" id="des">
          <ReactMarkDown children={currentCriteria.testdescription} />
        </div>
      </details>

      <form className="responseform" ref={formRef} noValidate>
        Required fields are indicated with a{" "}
        <span className="required_label">*</span>.
        <br />
        <div className="radioans" id="radioButtons">
          <fieldset aria-invalid={scoreButtonError} aria-required="true">
            <legend>
              {`Please choose one of the following answers:`}
              <span className="required_label">*</span>
              {scoreButtonError && (
                <strong className="error_label" id="score-error">
                  {" "}
                  Error: Please make a selection.
                </strong>
              )}
            </legend>
            {printReasonings()}
          </fieldset>
        </div>

        <label htmlFor="testedURL">
          <div>
            <strong>
              URL(s) Tested<span className="required_label">*</span>
            </strong>
            {urlError && (
              <strong className="error_label" id="url-error">
                {" "}
                Error: Please enter a valid URL.
              </strong>
            )}
          </div>
        </label>
        <textarea
          type="text"
          id="testedURL"
          name="testedURL"
          required
          value={urlText}
          onChange={(e) => setUrlText(e.target.value)}
          aria-invalid={urlError}
          aria-describedby={urlError ? "url-error" : null}
        />

        <label htmlFor="testNotes">
          <div>
            <strong>
              Testing Notes
              {notesRequired && <span className="required_label">*</span>}
            </strong>
            {notesError && notesRequired && (
              <strong className="error_label" id="notes-error">
                {" "}
                Error: Please enter testing notes.
              </strong>
            )}
          </div>
        </label>

        <textarea
          id="testNotes"
          name="testNotes"
          required={notesRequired}
          value={noteText}
          onChange={(e) => setNoteText(e.target.value)}
          aria-invalid={notesRequired && notesError}
          aria-describedby={notesRequired && notesError ? "notes-error" : null}
        />

        {showCapability && (
          <div id="capdrop">
            <label htmlFor="capability">
              <div>
                <strong>
                  Select a capability to fix this issue:
                  <span className="required_label">*</span>
                </strong>
                {capabilityError && (
                  <strong className="error_label" id="capability-error">
                    {" "}
                    Error: Please select a capability.
                  </strong>
                )}
              </div>
            </label>
            <select
              name="capability"
              id="capability"
              className="capselector"
              value={selectedCapability}
              onChange={(e) => setSelectedCapability(e.target.value)}
              required={showCapability}
              aria-invalid={showCapability && capabilityError}
              aria-describedby={
                showCapability && capabilityError ? "capability-error" : null
              }
            >
              <option value="">Select an Option</option>
              <option value="UX">UX</option>
              <option value="Copy">Copy</option>
              <option value="VisualDesign">Visual Design</option>
              <option value="XT">XT</option>
              <option value="Triage">Triage</option>
              <option value="NA">Not Applicable</option>
            </select>
          </div>
        )}

        
        <div className="popeTech-results">
        { (getItem(testId)?.scan && resultsMessages.length > 0) && (
          <ResultsTable data={resultsMessages} completed={isCompleted}></ResultsTable>
        )}
        { (getItem(testId)?.scan && TestMap.get(currentPage) && resultsMessages.length === 0) && (
          <>
            <h2>Pope Tech Scan Results</h2>
            <p>No issues found</p>
          </>
        )}
        </div> 
      
       
        {testIncompleteError && (
          <div
            className="test-incomplete-error-cont"
            style={
              currentPage === numQuestions
                ? { display: "block" }
                : { display: "none" }
            }
          >
            <strong className="error_label" id="test-incomplete-error">
              {testIncompleteError}
            </strong>
          </div>
        )}

        <div id="pager">
          {currentPage} of {numQuestions}
        </div>

        { !isEditCompleted && 
          <div className="ssnav">
            {currentPage > 0 && (
              <input
                type="Button"
                value="Previous"
                id="ssbtn"
                className="btn"
                name="prevButton"
                readOnly
                onClick={() => {
                  setCurrentPage(currentPage - 1);
                }}
              />
            )}
            <input
              type="Button"
              value={bttnValue}
              id="ssbtn2"
              className="btn"
              {...setButtonText()}
              onClick={() => onSubmit()}
              readOnly
            />
          </div>
        }
        { isEditCompleted && 
          <div className="ssnav">
            {currentPage > 0 && (
              <input
                type="Button"
                value="Previous"
                id="ssbtn"
                className="btn"
                name="prevButton"
                readOnly
                onClick={() => {
                  setCurrentPage(currentPage - 1);
                }}
              />
            )}
            <input
              type="Button"
              value={bttnValue}
              id="ssbtn2"
              className="btn"
              {...setButtonText()}
              onClick={() => onSubmit()}
              readOnly
            />
            <input
              type="Button"
              value="Save and Return to Report"
              id="ssbtn3"
              className="btn"
              onClick={() => onSubmit(null, true)}
              readOnly
            />
          </div>
        }
      </form>

      <ScoringModal 
        showModal={showAbandonModal} 
        setShowModal={setShowAbandonModal} 
        onConfirmCallback={() => {
          if (onAbandonCallback) onAbandonCallback();
          setShowAbandonModal(false);
        }}
        onCancelCallback={() => {
          setShowAbandonModal(false);
          setApplyFormFocus(true);
        }}
      />
    </div>
  ) : (
    <div></div>
  );
};

export default ScoringForm;

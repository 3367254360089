import { protectedResources } from "./authConfig";
import DOMPurify from 'dompurify';


export function editCriteria({ criteria, execute }) {
  const body = {
    criteriatypeid: criteria.criteriatypeid,
    capabilityid: criteria.capabilityid,
    criteria: DOMPurify.sanitize(criteria.criteria),
    rationale: DOMPurify.sanitize(criteria.rationale),
    testdescription: DOMPurify.sanitize(criteria.testdescription),
    feedbackintro: DOMPurify.sanitize(criteria.feedbackintro),
    alwaysapplies: criteria.alwaysapplies,
    criteriaid: criteria.criteriaid,
  };

  execute("PUT", protectedResources.updateCriteria.endpoint, protectedResources.updateCriteria.scopes, body);
}

export function createCriteria(criteria, execute, setCriteriaId) {
  const body = {
    criteriatypeid: criteria.criteriatypeid,
    capabilityid: criteria.capabilityid,
    criteria: DOMPurify.sanitize(criteria.criteria),
    rationale: DOMPurify.sanitize(criteria.rationale),
    testdescription: DOMPurify.sanitize(criteria.testdescription),
    feedbackintro: DOMPurify.sanitize(criteria.feedbackintro),
    alwaysapplies: criteria.alwaysApplies,
  };
  execute("POST", protectedResources.createCriteria.endpoint, protectedResources.createCriteria.scopes, body).then(
    (response) => {
      if (response && response.length > 0) {
        setCriteriaId(response[0].criteriaid);
      }
    }
  );
}

export function getCriteriaTypeByName(
  criteriaTypeName,
  execute,
  setCriteriaTypeByName
) {
  execute(
    "POST",
    protectedResources.getCriteriaTypeByName.endpoint,
    protectedResources.getCriteriaTypeByName.scopes,
    "criteriatypename=" + criteriaTypeName
  ).then((response) => {
    if (response) {
      setCriteriaTypeByName(response);
    } else {
      setCriteriaTypeByName(1);
    }
  });
}

export function updateGuidance(guidance, execute) {
  guidance.forEach((guide) => {
    if (guide.criteriaid === null) {
      return;
    }

    const body = {
      criteriaid: guide.criteriaid,
      score: guide.score,
      feedback: DOMPurify.sanitize(guide.feedback),
      feedbackid: guide.feedbackid,
    };
    execute("PUT", protectedResources.updateGuidance.endpoint, protectedResources.updateGuidance.scopes, body);
  });
}

export function createGuidance(guidance, criteriaId, execute) {
  guidance.forEach((guide) => {
    const body = {
      criteriaid: criteriaId,
      score: guide.score,
      feedback: DOMPurify.sanitize(guide.feedback),
    };
    execute("POST", protectedResources.createGuidance.endpoint, protectedResources.createGuidance.scopes, body);
  });
}

export function updateReasonings(reasonings, execute) {
  if (!reasonings.length > 0) {
    return;
  }

  const criteriaid = reasonings[0].criteriaid;

  reasonings.forEach((reasoning) => {
    const body = {
      criteriaid: criteriaid,
      score: reasoning.score === null ? -1 : reasoning.score,
      reasoning: DOMPurify.sanitize(reasoning.reasoning),
      reasoningid: reasoning.reasoningid,
    };
    execute("PUT", protectedResources.updateReasoning.endpoint, protectedResources.updateReasoning.scopes, body);
  });
}

export function createReasoning(reasonings, criteriaId, execute) {
  if (!reasonings.length > 0) {
    return;
  }

  reasonings.forEach((reasoning) => {
    const body = {
      criteriaid: criteriaId,
      score: reasoning.score === null ? -1 : reasoning.score,
      reasoning: DOMPurify.sanitize(reasoning.reasoning),
    };
    execute("POST", protectedResources.createReasoning.endpoint, protectedResources.createReasoning.scopes, body);
  });
}

export function updateImage(image, criteriaId, execute) {
  const body = {
    criteriaid: criteriaId,
    image: image.image,
    alttext: DOMPurify.sanitize(image.alttext),
    imageid: image.imageid,
  };
  execute("PUT", protectedResources.updateImage.endpoint, protectedResources.updateImage.scopes, body);
}

export function addImage(image, criteriaId, execute) {
  const body = {
    criteriaid: criteriaId,
    image: image.image,
    alttext: DOMPurify.sanitize(image.alttext),
  };
  execute("POST", protectedResources.addImage.endpoint, protectedResources.addImage.scopes, body);
}

export function deleteCriteria(criteriaId, execute) {
  const body = {
    criteriaid: criteriaId,
  };
  execute("DELETE", protectedResources.deleteCriteria.endpoint + criteriaId, protectedResources.deleteCriteria.scopes);
}

import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import "./UploadTestLanding.css";
import { Helmet } from "react-helmet";
import Dropzone from 'react-dropzone';
import { read } from "xlsx";
import { useMsal } from "@azure/msal-react";
import useFetchWithMsal from "../../hooks/useFetchWithMsal";
import { protectedResources } from "../../authConfig";
import DOMPurify from 'dompurify';

const UploadTestLanding = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { state } = location;
    const { instance } = useMsal();
    const account = instance.getActiveAccount();
    const { execute } = useFetchWithMsal({
        scopes: [
            ...protectedResources.uploadTest.scopes.write
        ],
    });

    let [fileUploaded, setFileUploaded] = useState(false);
    let [fileName, setFileName] = useState("");
    let [excelFile, setExcelFile] = useState(null);
    let [worksheetOptions, setWorksheetOptions] = useState([]);
    let [selectedWorksheetName, setSelectedWorksheetName] = useState("");
    let [columnNames, setColumnNames] = useState([]);
    let [showNextButton, setShowNextButton] = useState(false);
    let [selectedCriteriaCol, setSelectedCriteriaCol] = useState("");
    let [selectedScoreCol, setSelectedScoreCol] = useState("");
    let [selectedURLCol, setSelectedURLCol] = useState("");
    let [selectedNotesCol, setSelectedNotesCol] = useState("");
    let [selectedCapabiltyCol, setSelectedCapabiltyCol] = useState("");

    useEffect(() => {
        if (showNextButton) {
            if (selectedCriteriaCol === "" ||
                selectedScoreCol === "" ||
                selectedURLCol === "" ||
                selectedNotesCol === "" ||
                selectedCapabiltyCol === "" ||
                selectedWorksheetName === "") {
                setShowNextButton(false);
            }
        }

        if (selectedCriteriaCol !== "" &&
            selectedScoreCol !== "" &&
            selectedURLCol !== "" &&
            selectedNotesCol !== "" &&
            selectedCapabiltyCol !== "" &&
            selectedWorksheetName !== "") {
            setShowNextButton(true);
        }
    }, [selectedCriteriaCol, selectedScoreCol, selectedURLCol, selectedNotesCol, selectedCapabiltyCol, selectedWorksheetName]);

    const onNext = (spreadsheetFile = null, defaultColumnNames = null, criteriaColName = null, scoreColName = null, urlColName = null, notesColName = null, capabilityColName = null) => {
        let worksheet = spreadsheetFile !== null ? spreadsheetFile.Sheets["Accessibility_Indicator_Tests"] : excelFile.Sheets[selectedWorksheetName];
        var cells = Object.keys(worksheet);

        const columns = defaultColumnNames !== null ? defaultColumnNames : columnNames;
        let criteriaCol = String.fromCharCode(columns.indexOf(criteriaColName ?? selectedCriteriaCol) + 65);
        let scoreCol = String.fromCharCode(columns.indexOf(scoreColName ?? selectedScoreCol) + 65);
        let urlCol = String.fromCharCode(columns.indexOf(urlColName ?? selectedURLCol) + 65);
        let notesCol = String.fromCharCode(columns.indexOf(notesColName ?? selectedNotesCol) + 65);
        let capabilityCol = String.fromCharCode(columns.indexOf(capabilityColName ?? selectedCapabiltyCol) + 65);

        var criteria = [];
        var scores = [];
        var urls = [];
        var notes = [];
        var capabilities = [];

        for (var i = 0; i < Object.keys(cells).length; i++) {
            if (Number(cells[i].substring(1, cells[i].length)) === 1) {
                continue;
            }

            switch (cells[i].substring(0, 1)) {
                case criteriaCol:
                    criteria.push({ value: worksheet[cells[i]].v, index: Number(cells[i].substring(1, cells[i].length)) });
                    continue;
                case scoreCol:
                    scores.push({ value: worksheet[cells[i]].v, index: Number(cells[i].substring(1, cells[i].length)) });
                    continue;
                case urlCol:
                    urls.push({ value: worksheet[cells[i]].v, index: Number(cells[i].substring(1, cells[i].length)) });
                    continue;
                case notesCol:
                    notes.push({ value: worksheet[cells[i]].v, index: Number(cells[i].substring(1, cells[i].length)) });
                    continue;
                case capabilityCol:
                    capabilities.push({ value: worksheet[cells[i]].v, index: Number(cells[i].substring(1, cells[i].length)) });
                    continue;
                default:
                    continue;
            }
        }

        let companyId = 0;
        let projectName = "";
        let projectURL = "";

        if (state) {
            if (state.companyId) {
                companyId = state.companyId;
            }

            if (state.projectName) {
                projectName = DOMPurify.sanitize(state.projectName);
            }

            if (state.projectURL) {
                projectURL = state.projectURL;
            }
        }

        const userId = account.localAccountId;

        const body = {
            criteria,
            scores,
            urls,
            notes,
            capabilities,
            companyId,
            projectName,
            projectURL,
            userId,
            username: account.name,
            date: (spreadsheetFile !== null ? spreadsheetFile.Props.ModifiedDate : excelFile.Props.ModifiedDate) ?? Date()
        }

        execute("POST", protectedResources.uploadTest.endpoint, protectedResources.uploadTest.scopes, body).then(
            (response) => {
                if (response && response.testId) {
                    navigate("/ScoringScreen", {
                        state: { testId: response.testId }
                    });
                } else {
                    window.alert("We are not able to process the spreadsheet you have uploaded. Please ensure that you have selected the correct sheet and column names, and that these columns have valid data in them. If this error persists, you may need to proceed to the New Test page and enter your test manually.")
                }
            }
        );
    }

    const onFileUpload = (file) => {
        setFileUploaded(true);
        setFileName(file.name);

        var reader = new FileReader();
        reader.readAsBinaryString(file);

        reader.onload = function () {
            let workbook = read(reader.result, { type: "binary" });

            if (workbook.SheetNames.includes("Accessibility_Indicator_Tests")) {
                let worksheet = workbook.Sheets["Accessibility_Indicator_Tests"];
                var cells = Object.keys(worksheet);

                var colValues = [];
                for (var i = 0; i < Object.keys(cells).length; i++) {
                    if (Number(cells[i].substring(1, cells[i].length)) === 1) {
                        colValues.push(worksheet[cells[i]].v); //Contails all column names
                    }
                }

                if (colValues.includes("Criteria") &&
                    colValues.includes("Notes") &&
                    colValues.includes("Score (Automatically applied)") &&
                    colValues.includes("URL Tested") &&
                    colValues.includes("Select Capability to fix issue")) {
                    onNext(workbook, colValues, "Criteria", "Score (Automatically applied)", "URL Tested", "Notes", "Select Capability to fix issue");
                } else {
                    setExcelFile(workbook);
                    setWorksheetOptions(workbook.SheetNames);
                }
            } else {
                setExcelFile(workbook);
                setWorksheetOptions(workbook.SheetNames);
            }
        }

        reader.onerror = function (error) {
            console.error(error);
        };
    }

    const setSelectedWorksheet = (worksheetName) => {
        if (worksheetName === "" || worksheetName === "Select an Option") {
            setSelectedWorksheetName("");
            return;
        }

        setSelectedWorksheetName(worksheetName);

        let worksheet = excelFile.Sheets[worksheetName];
        var cells = Object.keys(worksheet);

        var colValues = [];
        for (var i = 0; i < Object.keys(cells).length; i++) {
            if (Number(cells[i].substring(1, cells[i].length)) === 1) {
                colValues.push(worksheet[cells[i]].v); //Contails all column names
            }
        }

        setColumnNames(colValues);
    }

    const onColumnSelect = (columnName, columnSelected) => {
        if (columnName === "Select an Option") {
            columnSelected = "";
        }

        switch (columnName) {
            case "criteria":
                setSelectedCriteriaCol(columnSelected);
                break;
            case "score":
                setSelectedScoreCol(columnSelected);
                break;
            case "url":
                setSelectedURLCol(columnSelected);
                break;
            case "notes":
                setSelectedNotesCol(columnSelected);
                break;
            case "capability":
                setSelectedCapabiltyCol(columnSelected);
                break;
            default:
                break;
        }
    }

    return (
        <>
            <Helmet>
                <title>Upload existing test from Excel spreadsheet</title>
            </Helmet>
            <div id="upload-test-landing-screen-container">
                <h1 id="upload-test-landing-screen-title">
                    Upload existing test from Excel spreadsheet
                </h1>
                <p id="upload-test-landing-desc">
                    Upload an existing complete or incomplete test from an Excel spreadsheet.
                    <br /><br />
                    Once you have uploaded a spreadsheet,
                    you will be navigated to the scoring screen to review that the information is correct.
                    If the spreadsheet is not in the expected format, you will need to provide the name of the
                    sheet with the responses, as well as which column corresponds to the data that is needed to parse the spreadsheet.
                    <br /><br />
                    All responses detected from the spreadsheet will be automatically saved.
                    The test will not be marked as complete until you validate the responses.
                    <br /><br />
                    Using the file drop below, drag and drop or select a test.
                </p>
                <div id="upload-test-landing-screen-upload-container">
                    {!fileUploaded &&
                        <Dropzone onDrop={acceptedFiles => onFileUpload(acceptedFiles[0])}>
                            {({ getRootProps, getInputProps }) => (
                                <div >
                                    <section>
                                        <div {...getRootProps()}>
                                            <input {...getInputProps()} accept="application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" />
                                            <svg
                                                id="upload-test-landing-screen-upload-icon"
                                                focusable="false"
                                                aria-hidden="true"
                                                viewBox="0 0 24 24"
                                                data-testid="NoteAddIcon"
                                                color="#FE414D"
                                            >
                                                <path d="M14 2H6c-1.1 0-1.99.9-1.99 2L4 20c0 1.1.89 2 1.99 2H18c1.1 0 2-.9 2-2V8l-6-6zm2 14h-3v3h-2v-3H8v-2h3v-3h2v3h3v2zm-3-7V3.5L18.5 9H13z"></path>
                                            </svg>
                                            <p id="upload-test-landing-screen-upload-text">Drag and drop an Excel file here, or click or hit enter to select files. Only accepts .xslx files.</p>
                                        </div>
                                    </section>
                                </div>
                            )}
                        </Dropzone>
                    }
                    {fileUploaded && fileName &&
                        <>
                            <p id="upload-test-landing-screen-upload-text-bold">File successfully uploaded</p>
                            <p id="upload-test-landing-screen-upload-text">{fileName}</p>
                            <input
                                type="Button"
                                value="Remove"
                                id="remove-test-landing-screen-button"
                                onClick={() => {
                                    setExcelFile(null);
                                    setFileName("");
                                    setFileUploaded(false);
                                }}
                                readOnly
                            />
                        </>
                    }
                </div>
            </div>
            <div id="bottom-container">

                <div id="upload-test-landing-screen-container-after-upload">
                    {fileUploaded && worksheetOptions.length > 0 &&
                        <>
                            <p>Please select the sheet with the test</p>
                            <select
                                name="worksheetNameSelect"
                                id="worksheetNameSelect"
                                onChange={(e) => setSelectedWorksheet(e.target.value)}
                                required
                            >
                                <option value="">
                                    Select an Option
                                </option>

                                {worksheetOptions.map((option) => (
                                    <option value={option}>
                                        {option}
                                    </option>
                                ))}

                            </select>
                        </>
                    }
                </div>
                <div id="upload-test-landing-screen-container-after-upload">
                    {fileUploaded && columnNames.length > 0 &&
                        <>
                            <p>Please select the column with the criteria.  It is likely named "Criteria"</p>
                            <select
                                name="worksheetNameSelect"
                                id="worksheetNameSelect"
                                onChange={(e) => onColumnSelect("criteria", e.target.value)}
                                required
                            >
                                <option value="">
                                    Select an Option
                                </option>

                                {columnNames.map((option) => (
                                    <option value={option}>
                                        {option}
                                    </option>
                                ))}

                            </select>
                        </>
                    }
                </div>
                <div id="upload-test-landing-screen-container-after-upload">
                    {fileUploaded && columnNames.length > 0 &&
                        <>
                            <p>Please select the column with the numerical score (0-5 or "Not applicable").  It is likely named "Score (Automatically applied)"</p>                        <select
                                name="worksheetNameSelect"
                                id="worksheetNameSelect"
                                onChange={(e) => onColumnSelect("score", e.target.value)}
                                required
                            >
                                <option value="">
                                    Select an Option
                                </option>

                                {columnNames.map((option) => (
                                    <option value={option}>
                                        {option}
                                    </option>
                                ))}

                            </select>
                        </>
                    }
                </div>
                <div id="upload-test-landing-screen-container-after-upload">
                    {fileUploaded && columnNames.length > 0 &&
                        <>
                            <p>Please select the column with the URL tested.  It is likely named "URL Tested"</p>
                            <select
                                name="worksheetNameSelect"
                                id="worksheetNameSelect"
                                onChange={(e) => onColumnSelect("url", e.target.value)}
                                required
                            >
                                <option value="">
                                    Select an Option
                                </option>

                                {columnNames.map((option) => (
                                    <option value={option}>
                                        {option}
                                    </option>
                                ))}

                            </select>
                        </>
                    }
                </div>
                <div id="upload-test-landing-screen-container-after-upload">
                    {fileUploaded && columnNames.length > 0 &&
                        <>
                            <p>Please select the column with notes.  It is likely named "Notes"</p>
                            <select
                                name="worksheetNameSelect"
                                id="worksheetNameSelect"
                                onChange={(e) => onColumnSelect("notes", e.target.value)}
                                required
                            >
                                <option value="">
                                    Select an Option
                                </option>

                                {columnNames.map((option) => (
                                    <option value={option}>
                                        {option}
                                    </option>
                                ))}

                            </select>
                        </>
                    }
                </div>
                <div id="upload-test-landing-screen-container-after-upload">
                    {fileUploaded && columnNames.length > 0 &&
                        <>
                            <p>Please select the sheet with the capability.  It is likely named "Select Capability to fix the issue"</p>
                            <select
                                name="worksheetNameSelect"
                                id="worksheetNameSelect"
                                onChange={(e) => onColumnSelect("capability", e.target.value)}
                                required
                            >
                                <option value="">
                                    Select an Option
                                </option>

                                {columnNames.map((option) => (
                                    <option value={option}>
                                        {option}
                                    </option>
                                ))}

                            </select>
                        </>
                    }
                </div>

                <div id="upload-test-landing-screen-container-after-upload">
                    {fileUploaded && showNextButton &&
                        <input
                            type="Button"
                            value="Upload"
                            id="upload-test-landing-screen-button"
                            onClick={() => onNext(null)}
                            readOnly
                        />
                    }
                </div>
            </div>

        </>
    );
};

export default UploadTestLanding;
